<template>
  <div>
    <c-navbar v-if="!hideNavbar">
      <template v-slot:title>
        {{ $t('Categories') }}
      </template>
      <template v-slot:buttons>
        <b-button variant="primary">{{$t('Add category')}}</b-button>
      </template>
    </c-navbar>
  </div>
</template>

<script>
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import {BButton} from "bootstrap-vue";
export default {
  name: "CategoriesList",
  props: {
    hideNavbar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CNavbar,
    BButton
  }
}
</script>